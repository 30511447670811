import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  click(event) {
    event.preventDefault()
    const id = event.target.href.split('#')[1];
    document.getElementById( id ).click();
    document.getElementById( id ).focus();
  }
}
