import { Controller } from "@hotwired/stimulus"

import Glider from 'glider-js/glider'

// <div class="glider-contain" data-controller="glider">
//   <div class="glider" data-glider-target="glider">
//     <div>your content here</div>
//     <div>your content here</div>
//     <div>your content here</div>
//     <div>your content here</div>
//   </div>

//   <button aria-label="Previous" class="glider-prev">«</button>
//   <button aria-label="Next" class="glider-next">»</button>
//   <div role="tablist" class="dots"></div>
// </div>

export default class extends Controller {
  static targets = ["glider"]

  connect() {
    this.glider = new Glider(this.gliderTarget, {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      },
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    })
  }

  disconnect() {
    this.glider.destroy()
  }
}