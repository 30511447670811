import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "hiddenInput"]

  getValuesFromOption(option) {
    const names = option.dataset.names.split(" ")
    const values = option.dataset.values.split(" ")
    return names.reduce((optionValues, name, index) => {
      if (values[index] !== "") optionValues[name] = values[index]
      return optionValues
    }, {});
  }

  convert() {
    const selectedOption = this.selectTarget.selectedOptions[0]
    const optionValues = this.getValuesFromOption(selectedOption)
    const inputByName = this.hiddenInputTargets.reduce((inputs, input) => {
      inputs[input.name] = input
      return inputs
    }, {});
    for (const name in inputByName) {
      inputByName[name].value = optionValues[name] || ""
      inputByName[name].dispatchEvent(new Event('change'))
    }
  }
}
