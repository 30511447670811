// DO NOT EDIT! - copied from backend

import { Controller } from "@hotwired/stimulus";

// obsługa skrótów klawiaturowych drzewa
class Key {
  constructor(identifier, element, getTreeItem, getGroup, toggleVisibility, setActiveTreeItem) {
    this.identifier = identifier;
    this.element = element;
    this.getTreeItem = getTreeItem;
    this.getGroup = getGroup;
    this.toggleVisibility = toggleVisibility;
    this.setActiveTreeItem = setActiveTreeItem;
  }

  press(event) {
    if (this.element.contains(document.activeElement)) {
      switch (event.key) {
        case "Enter": this.enter(event); break;
        case " ": this.space(event); break;
        case "ArrowDown": this.arrowDown(event); break;
        case "ArrowUp": this.arrowUp(event); break;
        case "ArrowRight": this.arrowRight(event); break;
        case "ArrowLeft": this.arrowLeft(event); break;
      }
    }
  }

  enter(event) {
    const treeItem = this.getTreeItem(document.activeElement)
    if (this.getGroup(treeItem)) {
      this.toggleVisibility(treeItem)
    } else {
      const link = treeItem.querySelector(`a`)
      link?.click()
    }
  }

  space(event) {
    event.preventDefault();
    const treeItem = this.getTreeItem(document.activeElement)
    const input = treeItem.querySelector(`input[type="checkbox"], input[type="radio"]`)
    if (input) {
      input.checked = !input.checked
      input.dispatchEvent(new Event("input"))
      input.dispatchEvent(new Event("change"))
    }
  }

  arrowDown(event) {
    event.preventDefault();
    const treeItem = this.getTreeItem(document.activeElement)
    let nextTreeItem = treeItem.nextElementSibling
    if (nextTreeItem.localName === "turbo-frame") nextTreeItem = nextTreeItem.children[0] // fix for trees with turbo-frames
    if (nextTreeItem) this.setActiveTreeItem(nextTreeItem)
  }

  arrowUp(event) {
    event.preventDefault();
    const treeItem = this.getTreeItem(document.activeElement)
    let previousTreeItem = treeItem.previousElementSibling
    if (!previousTreeItem && treeItem.parentElement.localName === "turbo-frame") { // fix for trees with turbo-frames
      previousTreeItem = treeItem.parentElement.previousElementSibling
    }
    if (previousTreeItem) this.setActiveTreeItem(previousTreeItem)
  }

  arrowRight(event) {
    const treeItem = this.getTreeItem(document.activeElement)
    if (treeItem) {
      if (treeItem.getAttribute("aria-expanded") === "true") {
        const childTreeItem = treeItem.querySelector(`[data-${this.identifier}-target="treeItem"]`)
        if (childTreeItem) this.setActiveTreeItem(childTreeItem)
      } else {
        this.toggleVisibility(treeItem, true)
      }
    }
  }

  arrowLeft(event) {
    const treeItem = this.getTreeItem(document.activeElement)
    if (treeItem) {
      if (treeItem.getAttribute("aria-expanded") === "true") {
        this.toggleVisibility(treeItem, false)
      } else {
        const parentTreeItem = this.getTreeItem(treeItem.parentElement)
        if (parentTreeItem) this.setActiveTreeItem(parentTreeItem)
      }
    }
  }
}

// zarządza drzewem i ustawia poprawne `role` dla elementów drzewa
// https://www.w3.org/TR/wai-aria-practices-1.1/#TreeView

// This tree controller works with specially annotated HTML like:
// <ol data-controller="tree--basic" data-action="keydown@document->tree--basic#keyPress">
//   <li aria-expanded="true" data-tree--basic-target="treeItem" data-action="click->tree--basic#onToggleVisibility">
//     Dir 1
//     <ol data-tree--basic-target="group">
//       <li aria-expanded="false" data-tree--basic-target="treeItem" data-action="click->tree--basic#onToggleVisibility">
//         Dir 1.1
//       </li>
//     </ol>
//   </li>
// </ol>
export default class extends Controller {
  static targets = [
    "treeItem",
    "group"
  ]
  static values = {
    selected: Array // selector list
  }

  connect() {
    this.selectedValue.forEach(selector => this.show({
      currentTarget: this.element.querySelector(selector)
    }))
    this.setRoleAttributes();
    this.setTabIndex(this.treeItemTargets[0])
    this.key = new Key(
      this.identifier,
      this.element,
      this.getTreeItem.bind(this),
      this.getGroup.bind(this),
      this.toggleVisibility.bind(this),
      this.setActiveTreeItem.bind(this)
    )
  }

  setActiveTreeItem(treeItem) {
    this.setTabIndex(treeItem)
    treeItem.focus()
  }

  keyPress(event) {
    this.key.press(event)
  }

  setTabIndex(treeItem) {
    this.treeItemTargets.forEach(el => el.setAttribute("tabindex", "-1"))
    treeItem?.setAttribute("tabindex", "0")
  }

  setRoleAttributes() {
    this.element.setAttribute("role", "tree")
    this.treeItemTargets.forEach(treeItem => {
      treeItem.setAttribute("role", "treeitem")
    });
    this.groupTargets.forEach(group => {
      group.setAttribute("role", "group")
    });
  }

  onToggleVisibility(event) {
    event.stopPropagation()
    const treeItem = this.getTreeItem(event.currentTarget)
    this.toggleVisibility(treeItem)
    this.setActiveTreeItem(treeItem)
  }

  toggleVisibility(treeItem, force) {
    const expanded = this.getGroup(treeItem) ? (
      (typeof force === "boolean") ? force : treeItem.getAttribute("aria-expanded") !== "true"
    ) : false
    treeItem.setAttribute("aria-expanded", expanded)
  }

  show(event) {
    const ancestorTreeItems = this.getAncestorTreeItems(this.getTreeItem(event.currentTarget))
    ancestorTreeItems.forEach(treeItem => this.toggleVisibility(treeItem, true))
  }

  getTreeItem(el) {
    return el.closest(`[data-${this.identifier}-target="treeItem"]`)
  }

  getGroup(treeItem) {
    return treeItem.querySelector(`[data-${this.identifier}-target="group"]`)
  }

  getAncestorTreeItems(treeItem) {
    let ancestorTreeItems = []
    const parentTreeItem = this.getTreeItem(treeItem.parentElement)
    if (parentTreeItem) {
      ancestorTreeItems = this.getAncestorTreeItems(parentTreeItem)
      ancestorTreeItems.push(parentTreeItem)
    }
    return ancestorTreeItems
  }
}
