import { Controller } from "@hotwired/stimulus"
import clipboard from 'clipboard'

export default class extends Controller {
  connect() {
    new clipboard(this.element, {
      text: function () {
        return `${document.location.origin}${document.location.pathname}`;
      }
    });
  }
}