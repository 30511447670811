import { Controller } from "@hotwired/stimulus"
import { useMatchMedia } from 'stimulus-use'

// Connects to data-controller="color-scheme"
// hugely inspired by https://getbootstrap.com/docs/5.3/customize/color-modes/#javascript
export default class extends Controller {
  static getStoredTheme = () => localStorage.getItem('theme')
  static setStoredTheme = theme => {
    localStorage.setItem('theme', theme)
    // prevent glitching by setting chosen theme during server-side rendering
    document.cookie = `theme=${theme}; path=/`
  }

  static getPreferredTheme = () => {
    return this.getStoredTheme()
      || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  }

  static setTheme = theme => {
    // TODO : use 'data-bs-theme' and proper bs5 dark mode; not the inverse filter
    const attrName = 'data-theme'
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute(attrName, 'dark')
    } else {
      document.documentElement.setAttribute(attrName, theme)
    }
  }

  static targets = [
    "themeBtn"
  ]

  showActiveTheme = (theme) => {
    const btnToActive = this.themeBtnTargets.find(btn => btn.dataset.colorSchemeThemeParam === theme)
    const svgOfActiveBtn = btnToActive.querySelector('svg use').getAttribute('href')

    // first svg
    this.element.querySelector('svg use').setAttribute("href", svgOfActiveBtn)

    this.themeBtnTargets.forEach(btn => {
      btn.classList.remove('active')
      btn.setAttribute('aria-pressed', 'false')
    })

    btnToActive.classList.add('active')
    btnToActive.setAttribute('aria-pressed', 'true')
  }

  connect () {
    const preferredTheme = this.constructor.getPreferredTheme()
    this.constructor.setTheme(preferredTheme)
    this.showActiveTheme(preferredTheme)

    useMatchMedia(this, {
      mediaQueries: {
        darkColorScheme: '(prefers-color-scheme: dark)'
      }
    })
  }

  switchTheme ({ params: { theme }}) {
    this.constructor.setStoredTheme(theme)
    this.constructor.setTheme(theme)
    this.showActiveTheme(theme)
  }

  darkColorSchemeChanged () {
    const storedTheme = this.constructor.getStoredTheme()
    if (storedTheme !== 'light' && storedTheme !== 'dark') {
      this.constructor.setTheme(this.constructor.getPreferredTheme())
    }
  }
}
