import { Controller } from "@hotwired/stimulus"

let collapseUniqueId = 0;

// collapse
// example:
// <div data-controller="collapse">
//   <a aria-expanded="false" data-collapse-target="button" data-action="collapse#handleToggle" href="#">Link</a>
//   <div data-collapse-target="target"></div>
// </div>

export default class extends Controller {
  static targets = ["button", "target"]

  connect() {
    this.toggle_class = this.data.get("toggle-class") || "d-none"
    this.toggle(this.isExpanded());
    this.assignMissingIdForTargets();
    this.buttonTarget.setAttribute("aria-controls", this.targetTargets.map(target => { return target.id }).join(" "));
  }

  isExpanded() {
    return this.buttonTarget.getAttribute("aria-expanded") === "true";
  }

  assignMissingIdForTargets() {
    this.targetTargets.forEach(target => {
      if (!target.hasAttribute('id')) {
        target.id = `collapse_${++collapseUniqueId}`;
      }
    });
  }

  toggle(force) {
    const bool = (typeof force === "boolean") ? force : !this.isExpanded();
    this.buttonTarget.setAttribute("aria-expanded", bool);
    this.targetTargets.forEach(target => {
      target.classList.toggle(this.toggle_class, !bool);
    });
  }

  handleToggle(event) {
    event.preventDefault();
    this.toggle();
  }
}
