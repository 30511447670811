import { Controller } from "@hotwired/stimulus"
import clipboard from 'clipboard'

export default class extends Controller {
  static targets = ["button", "source"]

  connect() {
    new clipboard(this.buttonTarget, {
      text: () => {
        return this.sourceTarget.textContent;
      }
    });
  }
}