import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["syncInput"]

  onChange(event) {
    switch (event.target.type) {
      case "checkbox":
        this.checkboxSync(event.target)
        break;

      default:
        this.inputSync(event.target)
        break;
    }
  }

  checkboxSync(target) {
    const checkbox = this.syncInputTargets.find((input) => {
      return input.name === target.name && input.value === target.value
    })
    if (checkbox) checkbox.disabled = !target.checked
  }

  inputSync(target) {
    const input = this.syncInputTargets.find((input) => {
      return input.name === target.name
    })
    if (input) {
      input.disabled = target.value === ""
      input.value = target.value
    }
  }
}