// DO NOT EDIT! - copied from backend

import BasicController from "./basic_controller";

// Rozszerza o obsługę checboxów.
// Obsługuje zaznaczanie potomków i przodków, przy zaznaczaniu checkboxa:
// 1. zaznacza/odznacza potomków
// 2. ustawia stan przodków zaznaczony/nie zaznaczony/nie zdefiniowany

// This tree controller works with specially annotated HTML like:
// <ol data-controller="tree--basic" data-action="keydown@document->tree--basic#keyPress">
//   <li aria-expanded="true" data-tree--basic-target="treeItem" data-action="click->tree--basic#onToggleVisibility">
//     <input data-tree--with-checkboxes-target="checkbox" data-action="tree--with-checkboxes#onToggleCheckbox" type="checkbox">
//     Dir 1
//     <ol data-tree--basic-target="group">
//       <li aria-expanded="false" data-tree--basic-target="treeItem" data-action="click->tree--basic#onToggleVisibility">
//         <input data-tree--with-checkboxes-target="checkbox" data-action="tree--with-checkboxes#onToggleCheckbox" type="checkbox">
//         Dir 1.1
//       </li>
//     </ol>
//   </li>
// </ol>
export default class extends BasicController {
  static targets = [
    "checkbox"
  ]

  connect() {
    super.connect()

    if (this.hasCheckboxTarget) {
      this.groupTargets.reverse().forEach(group => {
        this.setAncestorCheckboxState(this.getCheckbox(this.getTreeItem(group)))
      });
    }
  }

  onToggleCheckbox(event) {
    if (this.element.dataset.blockSubmit !== "true") {
      this.element.dataset.blockSubmit = true
      const checkbox = this.checkboxTargets.find(checkbox => event.currentTarget === checkbox)
      const group = this.getGroup(this.getTreeItem(checkbox))
      if (group) {
        const checkboxes = this.checkboxTargets.filter(el => group.contains(el))
        checkboxes.forEach(_checkbox => { this.toggleCheckbox(_checkbox, checkbox.checked) })
      }
      const ancestorTreeItems = this.getAncestorTreeItems(this.getTreeItem(checkbox))
      const ancestorCheckboxes = ancestorTreeItems.map(treeItem => this.getCheckbox(treeItem))
      ancestorCheckboxes.reverse().forEach(_checkbox => this.setAncestorCheckboxState(_checkbox))
      this.element.dataset.blockSubmit = false
    }
  }

  toggleCheckbox(checkbox, force = null) {
    checkbox.indeterminate = false
    checkbox.checked = (typeof force === "boolean") ? force : !checkbox.checked
    checkbox.dispatchEvent(new Event('change'))
  }

  setAncestorCheckboxState(checkbox) {
    const group = this.getGroup(this.getTreeItem(checkbox))
    const selected = this.getCheckbox(group, ":not(:indeterminate):checked")
    const unselected = this.getCheckbox(group, ":not(:indeterminate):not(:checked)")

    if (selected && unselected) {
      checkbox.checked = false
      checkbox.indeterminate = true
    } else if (selected && !unselected) {
      checkbox.checked = true
      checkbox.indeterminate = false
    } else {
      checkbox.checked = false
      checkbox.indeterminate = false
    }
    checkbox.dispatchEvent(new Event('change'))
  }

  getCheckbox(el, selector = "") {
    return el.querySelector(`[data-${this.identifier}-target="checkbox"]${selector}`)
  }
}
