import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    const params = (new URL(document.location)).searchParams
    const id = params.get('highlight')
    if (id) { this.highlight(id) }
  }

  highlight (id) {
    const el = document.getElementById(id)
    if (!el) { return }
    setTimeout(() => {
      el.scrollIntoView(true)
      this.animate(el)
    }, 0)
  }

  animate (el) {
    el.style.transition = 'opacity 0.2s'
    el.style.opacity = 0.3
    el.addEventListener('transitionend', () => {
      el.style.transition = 'opacity 0.6s'
      el.style.opacity = 1
    }, { once: true })
  }
}
