// This controller works with specially annotated HTML like:
//
// <div data-controller="search-conditions">
//   <template data-target="search-conditions.template">
//     <div><button type="button" data-action="search-conditions#handleRemoveCondition">Usuń</button></div>
//   </template>
//   <div><button type="button" data-action="search-conditions#handleRemoveCondition">Usuń</button></div>
//   <div data-target="search-conditions.insertBefore"></div>
//     <button type="button" data-action="search-conditions#handleAddCondition">Dodaj nowy warunek</button>
//   </div>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "insertBefore"]

  connect() {
    this.element[this.identifier] = this
  }

  handleAddCondition(event) {
    event.preventDefault()
    this.addCondition()
  }

  addCondition(indexId = "", value = "", queryType = null, operator = null) {
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    const i = this.element.querySelectorAll('.nested-fields').length + 1
    content = content.replace(/for="(.+)_(\d+)_(.+<\/label>)/g, `for="$1_${i}_$3`)
    content = content.replace(/id="(.+)_(\d+)_/g, `id="$1_${i}_`)
    content = content.replace(/name="(.+)\[(.+)\]\[(\d+)\]/g, `name=\"$1[$2][${i}]`)
    content = content.replace(/name="(.+)\[(.+)\]\[(\d+)\]\[value\]/g, `value="${value}" name=\"$1[$2][${i}][value]`)
    content = content.replace(new RegExp(`<option value="${indexId}">`, "g"), `<option value="${indexId}" selected>`)
    if (queryType) content = content.replace(new RegExp(`<option value="${queryType}">`, "g"), `<option value="${queryType}" selected>`)
    if (operator) content = content.replace(new RegExp(`<option value="${operator}">`, "g"), `<option value="${operator}" selected>`)
    this.insertBeforeTarget.insertAdjacentHTML('beforebegin', content)
    let afterInsertEvent = new Event('search-conditions:after-insert', { "bubbles": true })
    this.insertBeforeTarget.previousSibling.dispatchEvent(afterInsertEvent)
    this.element.querySelector(`[name="q[indexes_attributes][${i}][operator]"]`).focus()
  }

  handleRemoveCondition(event) {
    event.preventDefault()
    this.removeCondition(event.target)
  }

  removeCondition(target) {
    target.closest(".nested-fields").remove()
  }
}
