import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { localStorageId: String }

  connect() {
    this.element.hidden = localStorage.getItem(this.localStorageIdValue) == "true";
  }

  accept() {
    localStorage.setItem(this.localStorageIdValue, true)
    this.element.hidden = true;
  }
}
