// DO NOT EDIT! - copied from backend

import { Controller } from "@hotwired/stimulus"
// import LocalSettings from "../lib/local_settings"

export default class extends Controller {
  static targets = ["tab", "tabpanel"]
  static values = {
    settingKey: String // available ["nav.currentTab"]
  }

  connect() {
    this.element.tabs = this;
    this.addAriaAttrs()
    this.activateTab()
  }

  addAriaAttrs() {
    this.tabTargets.forEach(tab => {
      tab.setAttribute('role', 'tab')
      tab.setAttribute('aria-controls', tab.getAttribute('href').slice(1))
      tab.setAttribute('aria-selected', false)
    })
    this.tabpanelTargets.forEach(tabpanel => {
      tabpanel.setAttribute('role', 'tabpanel')
      tabpanel.setAttribute('aria-labelledby', `${tabpanel.id}-tab`)
    })
  }

  activateTab() {
    const tab_param = (new URL(window.location)).searchParams.get("tab")
    const activeTab = tab_param && this.tabTargets.find(tab => tab.getAttribute("aria-controls") === tab_param)
    this.selectTab(activeTab && activeTab.getAttribute('aria-controls')
      || (this.hasSettingKeyValue ? LocalSettings.getValue(this.settingKeyValue) : this.tabTarget.getAttribute('aria-controls')));
  }

  selectTab(tabpanelId) {
    if (this.hasSettingKeyValue) LocalSettings.setValue(this.settingKeyValue, tabpanelId)
    this.tabpanelTargets.forEach(tabpanel => {
      const condition = tabpanel.id === tabpanelId
      tabpanel.classList.toggle('active', condition)
      tabpanel.classList.toggle('show', condition)
    });
    this.tabTargets.forEach(tab => {
      const condition = tab.getAttribute('aria-controls') === tabpanelId
      tab.classList.toggle('active', condition)
      tab.setAttribute('aria-selected', condition)
    });
  }

  select(event) {
    event.preventDefault()
    const tab = event.currentTarget
    this.updateUrl(tab.getAttribute("aria-controls"))
    this.selectTab(tab.getAttribute('aria-controls'));
  }

  updateUrl(tab_name) {
    const url = new URL(window.location)
    url.searchParams.set("tab", tab_name)
    window.history.pushState({}, "", url)
  }
}
