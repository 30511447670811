import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["indexFilter", "searchCondition"]

  connect() {
    this.synchronizeSearchConditionsWithIndexFilters()
  }

  get searchConditionsController() {
    return document.querySelector(`[data-controller="search-conditions"]`)["search-conditions"]
  }

  synchronizeSearchConditionsWithIndexFilters() {
    const indexIds = this.indexFilterTargets.reduce((indexIds, indexFilter) => {
      indexIds.add(indexFilter.dataset.indexId)
      return indexIds
    }, new Set());
    const indexValues = {}
    // search for index values in this.searchConditionTargets
    indexIds.forEach(indexId => {
      indexValues[indexId] = indexValues[indexId] || []
      this.searchConditionTargets.forEach(searchCondition => {
        const values = this.getValuesFromSearchCondition(searchCondition)
        if (
          values.operator == "and"
          && values.id == indexId
          && values.queryType == "term"
        ) {
          indexValues[indexId].push(values.value)
        };
      });
    });
    // set values in this.indexFilterTargets
    for (const [id, values] of Object.entries(indexValues)) {
      this.indexFilterTargets.forEach(checkbox => {
        if (checkbox.dataset.indexId == id) {
          if (values.includes(checkbox.value)) {
            checkbox.checked = true
          } else {
            checkbox.checked = false
          }
        }
      });
    }
  }

  synchronizeIndexFilterWithSearchConditions(event) {
    if (event.target.checked) {
      this.addSearchCondition(event.target.dataset.indexId, event.target.value)
    } else {
      this.removeSearchCondition(event.target.dataset.indexId, event.target.value)
    }
  }

  addSearchCondition(indexId, value, queryType = "term", operator = "and") {
    if (
      this.searchConditionTargets.length <= 1
      && this.getValuesFromSearchCondition(this.searchConditionTarget).value == ""
    ) {
      this.searchConditionsController.removeCondition(this.searchConditionTarget)
    }
    this.searchConditionsController.addCondition(indexId, value, queryType, operator)
  }

  removeSearchCondition(indexId, value, queryType = "term", operator = "and") {
    this.searchConditionTargets.forEach(searchCondition => {
      const values = this.getValuesFromSearchCondition(searchCondition)
      if (
        values.operator == operator
        && values.id == indexId
        && values.queryType == queryType
        && values.value == value
      ) {
        this.searchConditionsController.removeCondition(searchCondition)
      };
    });
  }

  getValuesFromSearchCondition(conditionEl) {
    const operatorSelect = conditionEl.querySelector(`select[name$="[operator]"]`)
    const idSelect = conditionEl.querySelector(`select[name$="[id]"]`)
    const queryTypeSelect = conditionEl.querySelector(`select[name$="[query_type]"]`)
    const valueInput = conditionEl.querySelector(`input[name$="[value]"]`)
    return {
      operator: operatorSelect.selectedOptions[0].value,
      id: idSelect.selectedOptions[0].value,
      queryType: queryTypeSelect.selectedOptions[0].value,
      value: valueInput.value
    }
  }
}
