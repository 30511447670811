// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails";

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "./vendor"
import "chartkick/chart.js"

import "./controllers";
