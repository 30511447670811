import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-zoom"
export default class extends Controller {
  minFontSizePct = 75
  defaultFontSizePct = 100
  maxFontSizePct = 150

  minRangeValue = 0
  defaultRangeValue = 50
  maxRangeValue = 100

  rangeValueStep = 10

  storageKey = "textZoomRangeValue"

  static targets = [
    "range",
    "increaseBtn",
    "decreaseBtn",
    "resetBtn",
  ]

  connect () {
    this._setNewRangeValue(
      window.localStorage.getItem(this.storageKey)
      || this.defaultRangeValue
    )
  }

  increase () {
    this._setNewRangeValue(new Number(this.rangeTarget.value) + this.rangeValueStep)
  }

  decrease () {
    this._setNewRangeValue(new Number(this.rangeTarget.value) - this.rangeValueStep)
  }

  reset () {
    this._setNewRangeValue(this.defaultRangeValue)
  }

  onRangeChange (e) {
    this._setNewRangeValue(e.currentTarget.value)
  }

  _setNewRangeValue (rangeValue) {
    rangeValue = new Number(rangeValue)
    if (rangeValue < 0) rangeValue = 0
    if (rangeValue > 100) rangeValue = 100
    this.rangeTarget.value = rangeValue
    this._toggleBtns(rangeValue)
    this._setFontSize(this._rangeValueToFontSize(rangeValue))
    window.localStorage.setItem(this.storageKey, rangeValue)
  }

  _toggleBtns (rangeValue) {
    if (rangeValue == 0) {
      this.decreaseBtnTarget.disabled = true
    } else if (rangeValue == this.defaultRangeValue) {
      this.decreaseBtnTarget.disabled = false
      this.increaseBtnTarget.disabled = false
      this.resetBtnTarget.disabled = true
    } else if (rangeValue == 100) {
      rangeValue = 100
      this.increaseBtnTarget.disabled = true
    } else {
      this.decreaseBtnTarget.disabled = false
      this.increaseBtnTarget.disabled = false
      this.resetBtnTarget.disabled = false
    }
  }

  _setFontSize (percent) {
    document.documentElement.style.fontSize = `${percent}%`
  }

  // 0 → fontSize 75% (minFontSizePct)
  // 50 → fontSize 100%
  // 100 → fontSize 150% (maxFontSizePct)
  _rangeValueToFontSize (rangeValue) {
    let pct
    if (rangeValue < this.defaultRangeValue) {
      pct = ((rangeValue - this.minRangeValue) / (this.defaultRangeValue - this.minRangeValue))
        * (this.defaultFontSizePct - this.minFontSizePct) + this.minFontSizePct
    } else if (rangeValue > this.defaultRangeValue) {
      pct = ((rangeValue - this.defaultRangeValue) / (this.maxRangeValue - this.defaultRangeValue))
        * (this.maxFontSizePct - this.defaultFontSizePct) + this.defaultFontSizePct
    } else {
      pct =  100
    }
    // console.log('TextZoom', `rangeValue: ${rangeValue}, pct: ${pct}`)
    return pct
  }
}
