import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "input"]

  connect() {
    this.inputTargets.forEach(input => {
      if (input.checked) {
        input.parentElement.classList.add('active');
      }
    });
  }

  select(event) {
    event.target.parentElement.classList.toggle('active')
  }
}
