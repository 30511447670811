import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = [ "button", "tooltip" ]

  connect() {
    tippy(this.buttonTarget, {
      content: this.tooltipTarget.innerHTML,
      trigger: 'click',
      flipOnUpdate: 'true',
      maxWidth: 500,
      theme: 'light-border',
      placement: this.data.get("placement") || 'top',
      arrow: false
    });
  }
}