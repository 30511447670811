import { Controller } from "@hotwired/stimulus"
import Noty from 'noty';

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    this.notys = this.messageTargets.map(message => {
      return new Noty({
        text: `<span role="alert">${message.dataset.flashText}</div>`,
        type: message.dataset.flashType,
        theme: 'bkpan',
        timeout: 3500,
      })
    });
    this.notys.forEach(n => n.show())
  }

  disconnect() {
    this.notys.forEach(n => n.close())
  }
}
