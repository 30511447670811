import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "treeItem",
    "iframe"
  ]

  updateIframe(event) {
    event.preventDefault()
    const url = event.currentTarget.href
    this.iframeTarget.src = url
  }

  updateUrl(event) {
    const params = new URLSearchParams(location.search);
    params.set('res_id', event.params.id);
    history.pushState({}, event.params.name, `${location.pathname}?${params}${location.hash}`);
  }

  setCurrentTreeItem(e) {
    this.treeItemTargets.forEach(n => n.removeAttribute("aria-current"))
    e.target.closest(`[data-${this.identifier}-target="treeItem"]`).setAttribute("aria-current", true)
  }
}
