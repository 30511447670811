import { Controller } from "@hotwired/stimulus"

// example:
// <div data-controller="checkbox-toggle">
//   <button data-action="checkbox-toggle#off" type="button">off</button>
//   <button data-action="checkbox-toggle#on auto-submit#submit" type="button">on</button>
//   <input data-checkbox-toggle-target="checkbox"
//     data-action="change->form-sync#onChange change->checkbox-toggle#preventEvent change->auto-submit#submit"
//     type="checkbox" value="1">
//   <input data-checkbox-toggle-target="checkbox"
//     data-action="change->form-sync#onChange change->checkbox-toggle#preventEvent change->auto-submit#submit"
//     type="checkbox" value="2">
// </div>

export default class extends Controller {
  static targets = ["checkbox"]

  off() {
    this.element.dataset.blockSubmit = true
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
      const change = new Event('change')
      change.preventEvent = true
      checkbox.dispatchEvent(change)
    });
    this.element.dataset.blockSubmit = false
  }

  on() {
    this.element.dataset.blockSubmit = true
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
      const change = new Event('change')
      change.preventEvent = true
      checkbox.dispatchEvent(change)
    });
    this.element.dataset.blockSubmit = false
  }
}
