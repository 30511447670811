import { Controller } from "@hotwired/stimulus"

import AutocompleteCore from '@trevoreyre/autocomplete/AutocompleteCore.js'
import uniqueId from '@trevoreyre/autocomplete/util/uniqueId.js'
import getRelativePosition from '@trevoreyre/autocomplete/util/getRelativePosition.js'

// Creates a props object with overridden toString function. toString returns an attributes
// string in the format: `key1="value1" key2="value2"` for easy use in an HTML string.
class Props {
  constructor(index, selectedIndex, baseId) {
    this.id = `${baseId}-result-${index}`
    this.class = `${baseId}-result`
    this['data-result-index'] = index
    this.role = 'option'
    if (index === selectedIndex) {
      this['aria-selected'] = 'true'
    }
  }

  toString() {
    return Object.keys(this).reduce(
      (str, key) => `${str} ${key}="${this[key]}"`,
      ''
    )
  }
}

export default class extends Controller {
  static targets = [ "combobox", "input", "listbox" ]

  connect() {
    this.expanded = false
    this.loading = false
    this.position = {}
    this.resetPosition = true
    this.element.style.position = 'relative'
    this.baseId=uniqueId(`autocomplete-`)

    this.core = new AutocompleteCore({
      search: this.search.bind(this),
      autoSelect: false,
      setValue: this.setValue.bind(this),
      setAttribute: this.setAttribute.bind(this),
      onUpdate: this.handleUpdate.bind(this),
      onSubmit: () => {},
      onShow: this.handleShow.bind(this),
      onHide: this.handleHide.bind(this),
      onLoading: this.handleLoading.bind(this),
      onLoaded: this.handleLoaded.bind(this),
    })

    // Generate ID for results list if it doesn't have one
    if (!this.listboxTarget.id) {
      this.listboxTarget.id = uniqueId(`${this.baseId}-listbox-`)
    }

    this.comboboxTarget.setAttribute('role', 'combobox')
    this.listboxTarget.setAttribute('role', 'listbox')

    this.comboboxTarget.setAttribute('aria-expanded', 'false')
    this.comboboxTarget.setAttribute('aria-owns', this.listboxTarget.id)
    // this.comboboxTarget.setAttribute('aria-haspopup', 'listbox')
    this.inputTarget.setAttribute('aria-autocomplete', 'list')
    this.inputTarget.setAttribute('aria-controls', this.listboxTarget.id)
    this.listboxTarget.setAttribute('aria-labelledby', this.listboxTarget.id)

    document.body.addEventListener('click', this.handleDocumentClick.bind(this))
    this.inputTarget.addEventListener('input', this.core.handleInput)
    this.inputTarget.addEventListener('keydown', this.core.handleKeyDown)
    this.inputTarget.addEventListener('focus', this.core.handleFocus)
    this.inputTarget.addEventListener('blur', this.core.handleBlur)
    this.listboxTarget.addEventListener('mousedown', this.core.handleResultMouseDown)
    this.listboxTarget.addEventListener('click', this.core.handleResultClick)
    this.updateStyle()
  }

  search(input) {
    const url = this.data.get("url") + encodeURI(input)
    return new Promise(function(resolve){
      if (input.length < 2) {
        return resolve([])
      }
      fetch(url)
        .then((response) => response.json())
        .then((data) => resolve(data))
    })
  }

  setAttribute(attribute, value) {
    this.inputTarget.setAttribute(attribute, value)
  }

  setValue(result) {
    this.inputTarget.value = result ? this.getResultValue(result) : ''
  }

  getResultValue(result) {
    return result
  }

  renderResult(result, props) {
    return `<li ${props}>${this.getResultValue(result)}</li>`
  }

  handleShow() {
    this.expanded = true
    this.updateStyle()
  }

  handleHide() {
    this.expanded = false
    this.resetPosition = true
    this.updateStyle()
  }

  handleLoading() {
    this.loading = true
    this.updateStyle()
  }

  handleLoaded() {
    this.loading = false
    this.updateStyle()
  }

  handleDocumentClick(event){
    if (this.element.contains(event.target)) {
      return
    }
    this.core.hideResults()
  }

  handleSubmit(result){
    this.updateStyle()
    return false;
  }

  handleUpdate(results, selectedIndex){
    this.listboxTarget.innerHTML = ''
    results.forEach((result, index) => {
      const props = new Props(index, selectedIndex, this.baseId)
      const resultHTML = this.renderResult(result, props)
      if (typeof resultHTML === 'string') {
        this.listboxTarget.insertAdjacentHTML('beforeend', resultHTML)
      } else {
        this.listboxTarget.insertAdjacentElement('beforeend', resultHTML)
      }
    })

    if (selectedIndex > -1) {
      this.inputTarget.setAttribute('aria-activedescendant', `${this.baseId}-result-${selectedIndex}`)
    } else {
      this.inputTarget.removeAttribute('aria-activedescendant')
    }

    if (this.resetPosition) {
      this.resetPosition = false
      this.position = getRelativePosition(this.inputTarget, this.listboxTarget)
      this.updateStyle()
    }
    this.core.checkSelectedResultVisible(this.listboxTarget)
  }

  updateStyle() {
    this.inputTarget.removeAttribute('aria-expanded')
    this.comboboxTarget.setAttribute('aria-expanded', this.expanded)
    this.listboxTarget.classList.toggle('combobox__listbox--show', this.expanded)
    this.element.classList.toggle('combobox__wrapper--active', this.expanded)
  }
}