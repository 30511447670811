// DO NOT EDIT! - copied from backend

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    setTimeout(() => this.setFocusFromLocation());
  }

  setFocusFromLocation() {
    const focus = (new URLSearchParams(location.search)).get("focus")
    if (focus) {
      const el = document.getElementById(focus)
      el.dispatchEvent(new Event(`${this.identifier}:focus`));
      el.focus()
    }
  }

  submit(event) {
    if (event.currentTarget.id) this.formTarget.appendChild(this.createFocusInput(event.currentTarget.id))
    this.formTarget.submit()
  }

  // check if closest have [data-block-submit="true"]
  safeSubmit(event) {
    if (!event.currentTarget.closest(`[data-block-submit="true"]`)) {
      this.submit(event)
    }
  }

  createFocusInput(value) {
    const focusInput = document.createElement("input")
    focusInput.type = "hidden"
    focusInput.name = "focus"
    focusInput.value = value
    return focusInput
  }
}
